import { Typography } from "@hero/krypton"
import styled, { css } from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { SendIncreaseExposureLimitRequestButton } from "../../00_shared/components/SendIncreaseExposureLimitResquestButton"
import { useMerchantExposure } from "../../00_shared/hooks/useMerchantExposure.hook"
import { OutstandingPanel } from "../../Application/components/OutstandingPanel"
import { CreditWireTransferHeader } from "../components/Header/CreditWireTransferHeader"
import { CWTApplicationPreview } from "../components/NotActivated/ApplicationPreview"

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 50%;
  justify-content: center;
  flex-direction: column;
`

const Title = styled(Typography)`
  max-width: 48rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 2rem;
`

const FooterText = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 70%;
`

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 1.5rem 3rem;
  padding-right: 0;
  background-color: ${({ theme }) => theme.colors.white};
`

const Subtitle = styled(Typography)`
  color: #624aff;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const Content = styled(Typography)<{ $marginTop?: boolean }>`
  font-weight: 400;

  ${(props) =>
    props.$marginTop &&
    css`
      margin-top: 1.75rem;
    `};
`

const OutstandingPanelFloater = styled.div`
  position: absolute;
  width: 34rem;
  left: 55%;
  bottom: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.75rem;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
`

export const CwtNotActivatedScreen = () => {
  const { t } = useDashboardTranslation()

  const {
    data: merchantExposureData,
    loading: isMerchantExposureLoading,
    error: merchantExposureError,
  } = useMerchantExposure({
    skip: false,
  })

  if (isMerchantExposureLoading) {
    return null
  }

  return (
    <>
      <CreditWireTransferHeader />
      <PageWrapper>
        <Container>
          <Subtitle $variant="body-4-medium">
            {t("creditWireTransfer.notActivatedScreen.v2.subtitle").toUpperCase()}
          </Subtitle>
          <Title $variant="title-2-bold">{t("creditWireTransfer.notActivatedScreen.v2.title")}</Title>

          <FooterText>
            <Content $variant="body-4-medium">{t("creditWireTransfer.notActivatedScreen.v2.content.common")}</Content>
            <Content $marginTop $variant="body-4-medium">
              {merchantExposureData &&
              merchantExposureData.totalAllowedExposure &&
              merchantExposureData.totalAllowedExposure <= 0 ? (
                t("creditWireTransfer.notActivatedScreen.v2.content.case.unavailable")
              ) : (
                <>
                  {t("creditWireTransfer.notActivatedScreen.v2.content.case.pending.part1")}
                  &nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    {t("creditWireTransfer.notActivatedScreen.v2.content.case.pending.part2")}
                  </span>
                  &nbsp;
                  {t("creditWireTransfer.notActivatedScreen.v2.content.case.pending.part3")}
                </>
              )}
            </Content>
          </FooterText>
          <div style={{ display: "flex", gap: "0.5rem" }}>
            {/* <ActivateButton
          disabled={heroOnboardingState === "started"}
          leftIcon={BalanceIcon}
          onClick={async () => {
            // todo get openbaking url
            return;
            }}
            >
            {t("creditWireTransfer.notActivatedScreen.v2.button.reassessEligibility")}
            </ActivateButton> */}
            <SendIncreaseExposureLimitRequestButton />
          </div>
        </Container>
        <CWTApplicationPreview />
      </PageWrapper>

      {merchantExposureData &&
      merchantExposureData.totalAllowedExposure &&
      merchantExposureData.totalAllowedExposure <= 0 ? null : (
        <OutstandingPanelFloater>
          <OutstandingPanel
            variant="compact"
            isLoading={isMerchantExposureLoading}
            isError={!!merchantExposureError}
            totalAllowedExposure={merchantExposureData?.totalAllowedExposure ?? 0}
            totalUsedExposure={merchantExposureData?.totalInternalDebt ?? 0}
            title={t("creditWireTransfer.applicationForm.outstandingTitle")}
            subtitle={t("creditWireTransfer.applicationForm.outstandingSubtitle.disabled")}
            actions={
              <>
                <Typography $variant="caption-2" $color="grey.$500">
                  {t("creditWireTransfer.applicationForm.outstandingActionsTitle")}
                </Typography>
                <SendIncreaseExposureLimitRequestButton />
              </>
            }
          />
        </OutstandingPanelFloater>
      )}
    </>
  )
}
