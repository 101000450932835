import {
  DeprecatedButton,
  DeprecatedFieldError,
  DeprecatedFieldInput,
  DeprecatedFieldSelect,
  DeprecatedLabel,
  deprecatedToaster,
  Typography,
} from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import * as Sentry from "@sentry/react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { z } from "zod"
import { Card } from "../../../../../00_shared/components/Card"
import { Dialog, DialogContent } from "../../../../../00_shared/components/Dialog"
import { FlexContainer, FlexItem } from "../../../../../00_shared/components/Flex"
import { ListSelector } from "../../../../../00_shared/components/ListSelector"
import { ScrollArea } from "../../../../../00_shared/components/ScrollArea"
import { useGooglePlaces } from "../../../../../00_shared/hooks/useGooglePlaces.hook"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { EnabledDeliveryCountryCodes } from "../../00_shared/hooks/useCreateCard.hook"
import { formatDisplayedAddress } from "../../00_shared/utils"

const ContentContainer = styled.div`
  width: 30rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 10rem;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 1rem;
    word-break: break-word;
  }
`

const addressSchema = z.object({
  address1: z
    .string()
    .min(1, { message: "Address line 1 is required" })
    .max(255, { message: "Address line 1 cannot exceed 255 characters" })
    .trim(),
  address2: z.string().max(255, { message: "Address line 2 cannot exceed 255 characters" }).trim().optional(),
  address3: z.string().max(255, { message: "Address line 3 cannot exceed 255 characters" }).trim().optional(),
  address4: z.string().max(255, { message: "Address line 4 cannot exceed 255 characters" }).trim().optional(),
  zipCode: z
    .string()
    .min(1, { message: "Zip code is required" })
    .max(32, { message: "Zip code cannot exceed 32 characters" })
    .trim(),
  city: z
    .string()
    .min(1, { message: "City is required" })
    .max(255, { message: "City cannot exceed 255 characters" })
    .trim(),
  region: z.string().max(32, { message: "Region cannot exceed 32 characters" }).trim().optional(),
  country: z.string().length(2, { message: "Country is required" }).trim().default("FR"),
})

export type AddressFormValues = z.infer<typeof addressSchema>

const renderAddress = (addressPrediction: AddressPrediction) => (
  <Typography $variant="body-4-regular" $color="grey.$600">
    {addressPrediction.description}
  </Typography>
)

interface AddressPrediction {
  placeId: string
  description: string
}

interface AddAddressModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (address: AddressFormValues) => void
}

export const AddAddressModal: React.FC<AddAddressModalProps> = ({ open, onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<AddressFormValues>({
    resolver: zodResolver(addressSchema),
  })
  const { t } = useDashboardTranslation()

  const { getAddressPredictions, getFormattedAddressFromPlaceId } = useGooglePlaces()

  const [addressPredictions, setAddressPredictions] = useState<AddressPrediction[]>([])

  const fetchAddressPredictions = async (input: string) => {
    if (input.length > 2) {
      try {
        const predictions = await getAddressPredictions(input, Object.keys(EnabledDeliveryCountryCodes))
        setAddressPredictions(predictions)
      } catch (error) {
        Sentry.captureException(error)
      }
    } else {
      setAddressPredictions([])
    }
  }

  const handleSelectAddress = async (placeId: string) => {
    try {
      const formattedAddress = await getFormattedAddressFromPlaceId(placeId)

      if (formattedAddress) {
        const streetName = formatDisplayedAddress(
          formattedAddress.streetNumber,
          formattedAddress.streetName,
          EnabledDeliveryCountryCodes[formattedAddress.country as keyof typeof EnabledDeliveryCountryCodes],
        )
        setValue("address1", streetName)
        setValue("city", formattedAddress.city)
        setValue("zipCode", formattedAddress.zipCode)
        setValue("country", formattedAddress.country)

        setAddressPredictions([])
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleGoBack = () => {
    onClose()
  }

  const handleFormSubmit = async (data: AddressFormValues) => {
    try {
      onSubmit(data)
      onClose()
    } catch (error) {
      Sentry.captureException(error)
      deprecatedToaster.error("Failed to add address")
    }
  }

  return (
    <Dialog open={open} onOpenChange={handleGoBack}>
      <DialogContent $disableAnimations={true} width="100%" height="100%">
        <ScrollArea width="100%">
          <ContentContainer>
            <Card $variant="plain">
              <Typography $variant="title-2-bold">{t("issuing.addAddress.modal.title")}</Typography>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <FlexContainer $direction="column">
                  <FlexItem>
                    <DeprecatedLabel>{t("issuing.addAddress.modal.addressLine1.label")}</DeprecatedLabel>
                    <DeprecatedFieldInput
                      style={{ width: "100%" }}
                      placeholder={t("issuing.addAddress.modal.addressLine1.placeholder")}
                      {...register("address1")}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => fetchAddressPredictions(e.target.value)}
                    />
                    <ListSelector
                      items={addressPredictions}
                      onSelect={(item) => handleSelectAddress(item.placeId)}
                      isOpen={addressPredictions.length > 0}
                      renderItem={renderAddress}
                    />
                    <DeprecatedFieldError>{errors.address1?.message || <>&nbsp;</>}</DeprecatedFieldError>
                  </FlexItem>
                  <FlexItem>
                    <DeprecatedLabel>{t("issuing.addAddress.modal.addressLine2.label")}</DeprecatedLabel>
                    <DeprecatedFieldInput
                      style={{ width: "100%" }}
                      placeholder={t("issuing.addAddress.modal.addressLine2.placeholder")}
                      {...register("address2")}
                    />
                    <DeprecatedFieldError>{errors.address2?.message || <>&nbsp;</>}</DeprecatedFieldError>
                  </FlexItem>
                  <FlexContainer $justify="space-between" $gap=".5rem">
                    <FlexItem $grow={1} style={{ overflowWrap: "break-word", overflow: "auto" }}>
                      <DeprecatedLabel>{t("issuing.addAddress.modal.zipCode.label")}</DeprecatedLabel>
                      <DeprecatedFieldInput
                        style={{ width: "100%" }}
                        placeholder={t("issuing.addAddress.modal.zipCode.placeholder")}
                        {...register("zipCode")}
                      />
                      <DeprecatedFieldError>{errors.zipCode?.message || <>&nbsp;</>}</DeprecatedFieldError>
                    </FlexItem>
                    <FlexItem $grow={1} style={{ overflowWrap: "break-word", overflow: "auto" }}>
                      <DeprecatedLabel>{t("issuing.addAddress.modal.city.label")}</DeprecatedLabel>
                      <DeprecatedFieldInput
                        style={{ width: "100%" }}
                        placeholder={t("issuing.addAddress.modal.city.placeholder")}
                        {...register("city")}
                      />
                      <DeprecatedFieldError>{errors.city?.message || <>&nbsp;</>}</DeprecatedFieldError>
                    </FlexItem>
                  </FlexContainer>
                  <FlexItem>
                    <DeprecatedLabel>{t("issuing.addAddress.modal.country.label")}</DeprecatedLabel>
                    <DeprecatedFieldSelect
                      style={{ width: "100%" }}
                      placeholder={t("issuing.addAddress.modal.country.placeholder")}
                      {...register("country")}
                    >
                      {Object.keys(EnabledDeliveryCountryCodes).map((countryCode) => (
                        <option value={countryCode} key={countryCode}>
                          {t(`issuing.addAddress.modal.country.${countryCode.toLowerCase()}`)}
                        </option>
                      ))}
                    </DeprecatedFieldSelect>
                    <DeprecatedFieldError>{errors.country?.message || <>&nbsp;</>}</DeprecatedFieldError>
                  </FlexItem>
                  <DeprecatedButton $variant="primary" size="medium" type="submit">
                    {t("issuing.addAddress.modal.saveButton.text")}
                  </DeprecatedButton>
                </FlexContainer>
              </form>
            </Card>
          </ContentContainer>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
