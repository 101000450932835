import { DeprecatedButton, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import { Card } from "../../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../../00_shared/components/Flex"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { LedgerMovementFlow } from "../../../00_shared/business"
import { useCreateTransferNavigationContext } from "../../../01_context/createTransferNavigation.context"
import TransferSentIcon from "../../00_shared/icons/transfer-sent-icon.png"
import { TransferType, useCreateTransferContext } from "../CreateTransferContext"

export const TransferSentScreen = ({
  transactionId,
  transferDetails,
}: {
  transactionId: string
  transferDetails: {
    amount: number
    recipient: string
    transferType: TransferType | undefined
    debitedAccountId: string
  }
}) => {
  const navigate = useNavigate()
  const { t } = useCommonTranslation()

  const { clearState } = useCreateTransferContext()

  const { navigateToCreateTransfer } = useCreateTransferNavigationContext()

  const { recipient, transferType, amount } = transferDetails

  const amountInEuros = toEuros(amount)
  const message =
    transferType === TransferType.BENEFICIARY
      ? `${recipient} ${t("transfer.beneficiary.confirmed.subTitle.one")} ${amountInEuros} ${t("transfer.beneficiary.confirmed.subTitle.two")}`
      : `${t("transfer.accountToAccount.confirmed.subTitle.one")} ${recipient} ${t("transfer.accountToAccount.confirmed.subTitle.two")}`

  const handleClickNewTransfer = () => {
    clearState()
    navigateToCreateTransfer({ updateTransferOrigin: false })
  }

  const handleClickCheckTransfer = () => {
    if (transferType === TransferType.BENEFICIARY) {
      navigate(`/transfers/transactions/${transactionId}`, {
        state: {
          flow: LedgerMovementFlow.BUSINESS_ACCOUNT_EXTERNAL_PAYOUT,
          ledgerMovementId: "",
          transactionId,
          ledgerId: transferDetails.debitedAccountId,
        },
      })
      return
    }
    navigate(`/accounts/transactions/${transactionId}`, {
      state: {
        flow: LedgerMovementFlow.PRODUCT_LEDGER_TO_PRODUCT_LEDGER,
        ledgerMovementId: "",
        transactionId,
        ledgerId: transferDetails.debitedAccountId,
      },
      replace: true,
    })
  }

  return (
    <Card $variant="plain" style={{ paddingTop: "2rem" }}>
      <FlexContainer $direction="column" $justify="center" $align="center" $gap="2rem">
        <img src={TransferSentIcon} width={80} alt="Virtuelle card" />
        <FlexContainer $direction="column" $gap=".5rem" $align="center" $justify="center">
          <Typography $variant="title-1-bold" $color="grey.$600">
            {t("transfer.confirmed.title")}
          </Typography>
          <Typography $variant="body-3-regular" $color="grey.$500" style={{ maxWidth: "60%", textAlign: "center" }}>
            {message}
          </Typography>
        </FlexContainer>
        <FlexContainer $gap=".5rem" $direction="column" style={{ width: "100%" }}>
          <DeprecatedButton $fullWidth size="medium" onClick={handleClickNewTransfer}>
            {t("transfer.confirmed.button.newTransfer")}
          </DeprecatedButton>
          <DeprecatedButton $fullWidth $variant="tertiary" size="medium" onClick={handleClickCheckTransfer}>
            {t("transfer.confirmed.button.checkTransfer")}
          </DeprecatedButton>
        </FlexContainer>
      </FlexContainer>
    </Card>
  )
}
