import { ArrowRightIcon, DeprecatedButton, Typography } from "@hero/krypton"
import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { useAmplitude } from "../../../../../../00_shared/hooks/useAmplitude/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../../../01_context/businessAccount.context"
import banner from "./images/bannerbg.svg"
import money from "./images/moneyScreen.png"

const BannerTopUpYourAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 300px;
  align-items: center;
  margin-top: 1rem;
  background-image: url("${banner}");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 0.5rem;
`

const BannerContent = styled.div`
  display: flex;
  padding-left: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;
  width: 60%;
  padding-bottom: 40px;
  padding-top: 40px;
  gap: 32px;

  @media (max-width: 1264px) {
    width: auto;
    padding-right: 20px;
  }
`

const TitleSection = styled.div``
const MoneyImageWrapper = styled.img<{ $bannerWidth: number }>`
  margin-right: -40px;
  margin-bottom: 40px;
  width: 20rem;
  height: auto; // Pour garder le ratio

  display: ${({ $bannerWidth }) => ($bannerWidth < 670 ? "none" : undefined)};
`

const Title = styled(Typography).attrs<{ $bannerWidth: number }>(({ $bannerWidth }) => ({
  $variant: "aeonik-h2-bold",
  style: {
    fontSize: $bannerWidth < 670 ? "20px" : undefined,
    lineHeight: $bannerWidth < 670 ? "26px" : undefined,
  },
}))`
  background: var(--Gradient, linear-gradient(90deg, #a97eff 0%, #6624a8 33.33%, #b88be5 66.67%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 26px;
`
const Subtitle = styled(Typography).attrs<{ $bannerWidth: number }>(({ $bannerWidth }) => ({
  $variant: "aeonik-h2-bold",
  style: {
    fontSize: $bannerWidth < 670 ? "20px" : undefined,
    lineHeight: $bannerWidth < 670 ? "26px" : undefined,
  },
}))``

const Description = styled(Typography).attrs<{ $bannerWidth: number }>(({ $bannerWidth }) => ({
  $variant: "body-4-regular",
  style: {
    fontSize: $bannerWidth < 670 ? "0.8rem" : undefined,
  },
}))``

export const AccountTopUpBanner = () => {
  const { t } = useDashboardTranslation()
  const { merchantMainAccountId } = useBusinessAccountContext()
  const navigate = useNavigate()
  const { accountNb } = useParams<{ accountNb: string }>()
  const [bannerWidth, setBannerWidth] = useState(0)
  const { track } = useAmplitude()

  const bannerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!bannerRef.current) return

    const resizeObserver = new ResizeObserver(() => {
      const width = bannerRef.current?.offsetWidth || 0
      setBannerWidth(width)
    })

    resizeObserver.observe(bannerRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  const handleTopUpAccount = useCallback(() => {
    track({ eventType: "dashboard_welcome_banner_clicked" })
    if (accountNb) {
      navigate(`/accounts/${accountNb}/top-up`)
      return
    }
    navigate(`/accounts/${merchantMainAccountId}/top-up`)
  }, [track, accountNb, navigate, merchantMainAccountId])

  return (
    <BannerTopUpYourAccountWrapper ref={bannerRef}>
      <BannerContent>
        <TitleSection>
          <Title $bannerWidth={bannerWidth}>{t("ba.topUpBanner.title")}</Title>
          <Subtitle $bannerWidth={bannerWidth}>{t("ba.topUpBanner.subtitle")}</Subtitle>
        </TitleSection>
        <Description $bannerWidth={bannerWidth}>{t("ba.topUpBanner.description")}</Description>
        <DeprecatedButton onClick={handleTopUpAccount} size="medium" isLoading={false} rightIcon={ArrowRightIcon}>
          {t("ba.topUpBanner.action")}
        </DeprecatedButton>
      </BannerContent>
      <MoneyImageWrapper $bannerWidth={bannerWidth} src={money} alt="" />
    </BannerTopUpYourAccountWrapper>
  )
}
