import { BNPLKpis, useGetBnplKpis } from "../List.requests"
import styled from "styled-components"
import { Typography } from "@hero/krypton"
import { Skeleton } from "../../../../00_shared/components/Skeleton"
import { toKEuros } from "../../../../00_shared/utils/currency.converter"
import { T, useDashboardTranslation } from "../../../../01_technical/translations"
import { DateTime } from "luxon"
import { FC } from "react"

const Card = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$300};
  box-shadow: ${({ theme }) => theme.shadows.mid};

  @media (min-width: 768px) {
    padding: 1rem 1.5rem;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 60rem;
`

const Grid = styled.div`
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 1fr;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const KPICard: FC<{ label: string; value: string | null; loading: boolean }> = (props) => (
  <Card>
    <Typography $variant="caption-2">{props.label}</Typography>
    {props.loading ? (
      <Skeleton height="1.75rem" width="10rem" />
    ) : (
      <Typography $variant="body-2-semibold">{props.value}</Typography>
    )}
  </Card>
)

const KPIS = [
  {
    key: "daily",
    getLabel: (t: T) => t("collection.kpis.daily"),
    getValue: (data: BNPLKpis) => toKEuros(data.daily),
  },
  {
    key: "monthly",
    getLabel: (t: T) =>
      t("collection.kpis.monthly", {
        date: DateTime.now().startOf("month").toLocaleString(),
      }),
    getValue: (data: BNPLKpis) => toKEuros(data.monthly),
  },
  {
    key: "total",
    getLabel: (t: T) => t("collection.kpis.total"),
    getValue: (data: BNPLKpis) => toKEuros(data.total),
  },
]

export const CollectionKPIs = () => {
  const { loading, data } = useGetBnplKpis()
  const { t } = useDashboardTranslation()

  return (
    <Container>
      <Grid>
        {KPIS.map(({ key, getLabel, getValue }) => (
          <KPICard key={key} label={getLabel(t)} value={data ? getValue(data) : null} loading={loading || !data} />
        ))}
      </Grid>
    </Container>
  )
}
