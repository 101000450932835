import { createContext, useCallback, useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

type CreateTransferNavigationContextType = {
  navigateToCreateTransfer: (options: { updateTransferOrigin: boolean }) => void
  returnToCreateTransferOrigin: () => void
  createTransferOrigin: string | null
}

const CreateTransferNavigationContext = createContext<CreateTransferNavigationContextType>(
  {} as CreateTransferNavigationContextType,
)

export const useCreateTransferNavigationContext = () => useContext(CreateTransferNavigationContext)

export const CreateTransferNavigationProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [createTransferOrigin, setCreateTransferOrigin] = useState<null | string>(null)

  const navigateToCreateTransfer = useCallback(
    (options: { updateTransferOrigin: boolean }) => {
      if (options.updateTransferOrigin) {
        setCreateTransferOrigin(location.pathname)
      }

      navigate("/transfers/create")
    },
    [location.pathname, navigate],
  )

  const returnToCreateTransferOrigin = useCallback(() => {
    navigate(createTransferOrigin ?? "/transfers")
  }, [navigate, createTransferOrigin])

  return (
    <CreateTransferNavigationContext.Provider
      value={{
        navigateToCreateTransfer,
        returnToCreateTransferOrigin,
        createTransferOrigin,
      }}
    >
      {children}
    </CreateTransferNavigationContext.Provider>
  )
}
