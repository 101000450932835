import { DeprecatedButton, DeprecatedField, DeprecatedLabel, DeprecatedModal, Typography } from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, useForm } from "react-hook-form"
import styled from "styled-components"
import { z } from "zod"

import { useCommonTranslation } from "../../../../../01_technical/translations"

// krypton v1.5
import { FlexContainer } from "../../../../../00_shared/components/Flex"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../00_shared/components/Select"
import { useAmplitude } from "../../../../../00_shared/hooks/useAmplitude/useAmplitude.hook"
import { DateStringSchema } from "../../../../../00_shared/utils/commonZodSchemas.utils"
import { useBankStatementExport } from "../hooks/useBankStatementExport"

interface ExportTransactionsModalProps {
  isOpen: boolean
  close: () => void
  ledgerIds: string[]
}

type FormatType = "csv"

export const ExportTransactionsModal = ({ isOpen, close, ledgerIds }: ExportTransactionsModalProps) => {
  const { t } = useCommonTranslation()
  const { track } = useAmplitude()

  const exportFormSchema = z
    .object({
      start: DateStringSchema(t),
      end: DateStringSchema(t),
      format: z.enum(["csv"]),
    })
    .refine((data) => data.start <= data.end, {
      message: t("formValidation.date.invalidRange"),
      path: ["toDatetime"],
    })

  type SchemaType = z.infer<typeof exportFormSchema>

  const { handleSubmit, formState, register, control } = useForm<SchemaType>({
    resolver: zodResolver(exportFormSchema),
  })

  const { exporting, exportBankStatement } = useBankStatementExport()

  const handleExportClicked = handleSubmit(({ start, end, format }) => {
    const startDate = new Date(start)
    startDate.setHours(0, 0, 0, 0)
    const endDate = new Date(end)
    endDate.setHours(23, 59, 59, 999)

    exportBankStatement(ledgerIds, startDate, endDate, format).then(() => {
      close()
    })
  })

  const formats = [
    {
      key: "csv",
      value: t("account.transaction.list.exportModal.format.csv"),
    },
  ]

  return (
    <DeprecatedModal isOpen={isOpen} onClose={close}>
      <Wrapper>
        <Typography as="h1" $variant="title-2-bold">
          {t("account.transaction.list.exportModal.title")}
        </Typography>
        <Typography as="h2" $variant="body-4-regular" $color="grey.$500">
          {t("account.transaction.list.exportModal.subtitle")}
        </Typography>

        <Form onSubmit={handleExportClicked}>
          <FlexContainer $direction="column" $gap="4px">
            <Controller
              control={control}
              name="format"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  onValueChange={(value: FormatType) => {
                    onChange(value)
                  }}
                  value={value}
                  error={!!error}
                  errorMessage={error?.message}
                >
                  <DeprecatedLabel>{t("account.transaction.list.exportModal.format")}</DeprecatedLabel>
                  <SelectTrigger>
                    <SelectValue placeholder={t("account.transaction.list.exportModal.format.placeholder")} />
                  </SelectTrigger>
                  <SelectContent>
                    {formats.map((format) => (
                      <SelectItem key={format.key} value={format.key}>
                        {format.value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            ></Controller>
          </FlexContainer>
          <DateStyled>
            <FieldStyled
              fieldLabel={t("account.transaction.list.exportModal.operationDate")}
              type="date"
              aria-invalid={!!formState.errors.start}
              {...register("start")}
              errorMessage={formState.errors.start?.message}
              $inline
              $hasLabel={true}
            />
            <Typography as="h2" $variant="body-4-regular">
              {t("account.transaction.list.exportModal.operationDate.to")}
            </Typography>
            <FieldStyled
              type="date"
              aria-invalid={!!formState.errors.end}
              {...register("end")}
              errorMessage={formState.errors.end?.message}
              $inline
              $hasLabel={false}
            />
          </DateStyled>
          <ButtonContainer>
            <DeprecatedButton type={"submit"} size="medium" disabled={exporting} isLoading={exporting}>
              {t("account.transaction.list.exportModal.title")}
            </DeprecatedButton>
          </ButtonContainer>
        </Form>
      </Wrapper>
    </DeprecatedModal>
  )
}

const Wrapper = styled.div`
  padding: 0 32px 0.875rem;
`

const Form = styled.form`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const FieldStyled = styled(DeprecatedField)<{ $hasLabel?: boolean }>`
  margin-top: ${({ $hasLabel }) => ($hasLabel ? "0.25rem" : "1.5rem")};
  margin-left: 0;
  width: 100%;
`

const DateStyled = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  margin-top: 1.25rem;

  & > label {
    flex: 1;
  }
`

const ButtonContainer = styled.div`
  width: "auto";
`
