import { add, Identify, identify, init, reset, setGroup, setUserId, track } from "@amplitude/analytics-browser"
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser"
import { useCallback, useEffect, useRef } from "react"
import { AMPLITUDE_API_KEY } from "../../../env_variables"
import { AmplitudeTracker } from "./Amplitude.tracker.types"

interface UserProperty {
  [key: string]: string
}

export const useAmplitude = () => {
  const isInitialized = useRef(false)

  useEffect(() => {
    if (AMPLITUDE_API_KEY && !isInitialized.current) {
      const sessionReplayTracking = sessionReplayPlugin()
      init(AMPLITUDE_API_KEY, {
        autocapture: false,
      })
      add(sessionReplayTracking)

      isInitialized.current = true
    }
  }, [])

  const setUserProperties = (userProperties: UserProperty[]) => {
    const identifyEvent = new Identify()
    for (const key in userProperties) {
      identifyEvent.set(key, userProperties[key])
    }
    identify(identifyEvent, {})
  }

  const resetFn = AMPLITUDE_API_KEY ? reset : () => undefined
  const setUserIdFn = AMPLITUDE_API_KEY ? setUserId : () => undefined
  const setUserPropertiesFn = AMPLITUDE_API_KEY ? setUserProperties : () => undefined
  const setGroupFn = AMPLITUDE_API_KEY ? setGroup : () => undefined

  const amplitudeTrack = useCallback((args: AmplitudeTracker) => {
    if (!AMPLITUDE_API_KEY) {
      return () => undefined
    }

    if ("page" in args) {
      track(args.eventType, { page: args.page })
    }
    track(args.eventType)
  }, [])

  return {
    track: amplitudeTrack,
    reset: resetFn,
    setUserId: setUserIdFn,
    setUserProperties: setUserPropertiesFn,
    setGroup: setGroupFn,
  }
}
